import { IStyle } from '@fluentui/react';
import { IH2OTheme, mediaNoDesktop, useClassNames, useTheme } from '@h2oai/ui-kit';

import { ClassNamesFromIStyles } from '../../utils/models';
import NavigationWrapper from './NavigationWrapper';

interface IDashboardStyles {
  dasboard: IStyle;
  title: IStyle;
  widgetsContainer: IStyle;
}

const dashboardStyles = (theme: IH2OTheme): IDashboardStyles => {
  return {
    dasboard: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: '10px 40px 20px 40px',
      // Adds extra margin to prevent "Need help?" button from overlapping.
      margin: '0px 20px',
    },
    title: {
      marginTop: 0,
      // TODO: Remove once theme is used somewhere else.
      color: theme.semanticColors?.textPrimary,
    },
    widgetsContainer: {
      display: 'flex',
      flexGrow: 1,
      width: '100%',
      ...mediaNoDesktop({
        flexWrap: 'wrap',
      }),
    },
  };
};

const Dashboard = () => {
  const theme = useTheme(),
    classNames = useClassNames<IDashboardStyles, ClassNamesFromIStyles<IDashboardStyles>>(
      'dashboard',
      dashboardStyles(theme)
    );

  return (
    <NavigationWrapper>
      <div className={classNames.dasboard}>
        <h1 className={classNames.title}>Dashboard</h1>
        <div className={classNames.widgetsContainer}></div>
      </div>
    </NavigationWrapper>
  );
};

export default Dashboard;
