import { Stack } from '@fluentui/react';
import { detailsHeaderStylesBasic } from '@h2oai/ui-kit';
import { useEffect, useRef, useState } from 'react';

import List from '../../../components/ListPages/List';
import ListPage from '../../../components/ListPages/ListPage';
import { defaultWorkspaceName } from '../../../notebook/defaults';
import { useEntity } from '../../../notebook/entity/hooks';
import { type Entity } from '../../../notebook/entity/types';
import { stackStylesInnerPivotPage } from '../../../themes/themes';

export interface EntityDataContainerProps<EntityModel> {
  entity: Entity<EntityModel>;
}

export default function EntityDataContainer<EntityModel>({ entity }: EntityDataContainerProps<EntityModel>) {
  const { columns, canBeReordered, actions } = entity;
  const { List: hasList } = actions || undefined;
  const entityDataConnector = useEntity();
  const [data, setData] = useState<EntityModel[] | undefined>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataRetrievalError, setDataRetrievalError] = useState<string>();
  const showNoDataMessage = useRef<boolean>(false);

  const loadData = async () => {
    setLoading(true);
    try {
      const requestParams = { parent: defaultWorkspaceName };
      const entities = (await entityDataConnector.list(entity, requestParams)) as EntityModel[];

      if (!entities || entities.length === 0) {
        showNoDataMessage.current = true;
        setData([]);
      } else {
        showNoDataMessage.current = false;
        setData(entities);
      }
    } catch (error: any) {
      setDataRetrievalError(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Stack tokens={{ childrenGap: 5 }} styles={{ root: { paddingTop: 5 } }}>
      {hasList && (
        <ListPage
          showFilterButton={false}
          showNoDataPage={showNoDataMessage.current}
          showSearchInput={false}
          error={dataRetrievalError}
          loading={loading}
          copy={{
            title: 'Notebook Admin Settings',
            subtitle: '',
            loadingMessage: `Loading ${entity.displayName}s`,
            noDataMessage: 'No data.',
          }}
          showPageTitle={false}
          stackStyles={stackStylesInnerPivotPage}
        >
          <List<EntityModel>
            dataTest="entity-model-list"
            items={data || []}
            columns={columns}
            canBeReordered={canBeReordered}
            orderField={'order'}
            onShouldVirtualize={process.env.NODE_ENV === 'test' ? () => false : undefined}
            getKey={(col) => col.key}
            isHeaderVisible={true}
            headerStyles={detailsHeaderStylesBasic}
            showSelectAllButton={false}
          />
        </ListPage>
      )}
    </Stack>
  );
}
