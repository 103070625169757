import { IStyle } from '@fluentui/react';
import { IH2OTheme, useClassNames, useTheme } from '@h2oai/ui-kit';

import { ClassNamesFromIStyles } from '../../utils/models';
import NavigationWrapper from './NavigationWrapper';

interface IDeploymentsStyles {
  container: IStyle;
  title: IStyle;
}

const deploymentsStyles = (theme: IH2OTheme): IDeploymentsStyles => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: '10px 40px 20px 40px',
      // Adds extra margin to prevent "Need help?" button from overlapping.
      margin: '0px 20px',
    },
    title: {
      marginTop: 0,
      // TODO: Remove once theme is used somewhere else.
      color: theme.semanticColors?.textPrimary,
    },
  };
};

const Deployments = () => {
  const theme = useTheme(),
    classNames = useClassNames<IDeploymentsStyles, ClassNamesFromIStyles<IDeploymentsStyles>>(
      'deployments',
      deploymentsStyles(theme)
    );

  return (
    <NavigationWrapper>
      <div className={classNames.container}>
        <h1 className={classNames.title}>Deployments</h1>
      </div>
    </NavigationWrapper>
  );
};

export default Deployments;
