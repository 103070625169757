import type { IColumn } from '@fluentui/react';

import { MetadataCell } from '../components/ListPages/MetadataCell';

const noField = 'no-field';

type ProcessValueFunction = (data?: any) => any;

export const onRenderDataCell = <EntityModel,>(
  item: EntityModel,
  _?: number,
  column?: IColumn,
  processValue?: ProcessValueFunction
) => (
  <MetadataCell title={column?.name}>
    {processValue ? processValue(item[column?.fieldName || 'name']) : item[column?.fieldName || 'name']}
  </MetadataCell>
);

export const onRenderBooleanCell: typeof onRenderDataCell = <EntityModel,>(
  item: EntityModel,
  _i?: number,
  column?: IColumn
) => <MetadataCell title={column?.name}>{item[column?.fieldName || noField] ? 'Yes' : 'No'}</MetadataCell>;
