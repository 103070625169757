import type { IColumn } from '@fluentui/react';

import { MetadataCell } from '../../components/ListPages/MetadataCell';
import { kernelImageTypeMap } from '../defaults';
import type { KernelImage } from '../gen/ai/h2o/notebook/v1/kernel_image_pb';
import type { Entity, MessageText } from './types';

export const onRenderName = <EntityModel,>(item: EntityModel, _?: number, column?: IColumn) => (
  <MetadataCell title={item[column?.fieldName || 'name']}>{getResourceId(item[column?.key || 'name'])}</MetadataCell>
);

export const onRenderKernelImageType = (item: KernelImage, _?: number, column?: IColumn) => (
  <MetadataCell title={column?.name}>{kernelImageTypeMap[item?.type || '']}</MetadataCell>
);

export const completeMessageText = (messageText: MessageText | undefined, sourceEntity: Entity<KernelImage>) => {
  if (!sourceEntity) return messageText;

  return {
    ...messageText,
    group: sourceEntity.displayName,
  };
};

export const toText = ({ group, action, subject, object }: MessageText) => {
  let message = 'Notebook Admin Settings';

  if (group) {
    message += `${group.includes(':') ? ' ' : ': '} ${group}`;
  }

  if (subject) {
    message += ` ${subject}`;
  }

  message += ` ${action || 'action occurred '}`;
  message += ` ${object || 'successfully'}`;

  return message.replaceAll('::', ':');
};

export const bytesToGiB = (bytes: number | string): string => {
  if (typeof bytes === 'string') {
    bytes = parseInt(bytes, 10);
  }

  if (typeof bytes !== 'number' || bytes < 0) {
    throw new Error('Input must be a non-negative number or a valid string representation of a number');
  }

  return `${bytes / 1024 ** 3} GiB`;
};

export const getResourceId = (resource?: string | undefined) => resource?.split('/').pop();
