import { MessageBarType } from '@fluentui/react';
import { useToast } from '@h2oai/ui-kit';

import { fetchWrapRPC, getAccessToken } from '../../services/api';
import { useCloudPlatformDiscovery } from '../../utils/hooks';
import { handleErrMsg } from '../../utils/utils';
import { defaultBasePath } from '../defaults';
import { EntitiesMap } from './services';
import { type Entity, EntityActionType, type MessageText } from './types';
import { completeMessageText, toText } from './utils';

export function useEntity() {
  const cloudPlatformDiscovery = useCloudPlatformDiscovery();
  const { addToast } = useToast();
  const basePath = Object(cloudPlatformDiscovery).notebookApiUrl || defaultBasePath;
  const requestConfig = { basePath, bearerToken: getAccessToken() };
  const defaultErrorMessage = 'Notebook Admin Settings Error';

  const act = async <EntityModel, EntityRequest>(
    entity: Entity<EntityModel>,
    type: EntityActionType,
    request: EntityRequest,
    messageText?: MessageText,
    suppressToast = false,
    abortController?: AbortController
  ) => {
    const { actions } = entity;
    const action = actions[type];
    const rpc = action?.rpc;

    if (rpc) {
      try {
        let response;

        if (abortController) {
          response = await fetchWrapRPC(rpc, requestConfig)(request, { signal: abortController.signal });
        } else {
          response = await fetchWrapRPC(rpc, requestConfig)(request);
        }

        if (!suppressToast) {
          addToast({
            message: toText(completeMessageText(messageText, EntitiesMap[entity.type]) || {}),
            messageBarType: MessageBarType.success,
          });
        }

        return response[action?.responseKey || 'data'] as EntityModel[];
      } catch (error: any) {
        if (!suppressToast) {
          addToast({
            message: `${defaultErrorMessage}: ${handleErrMsg(error.message)}`,
            messageBarType: MessageBarType.error,
          });
        }

        throw error;
      }
    }

    return undefined;
  };

  const list = async <EntityModel, EntityRequest>(
    e: Entity<EntityModel>,
    req: EntityRequest,
    abortController?: AbortController
  ) => {
    return await act(e, EntityActionType.List, req, undefined, true, abortController);
  };

  return { list };
}
