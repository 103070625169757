import { type IDropdownOption } from '@fluentui/react';
import { type IColumn } from '@fluentui/react/lib/DetailsList';

import { type RPC } from '../gen/runtime';

export type MessageText = {
  group?: string;
  subject?: string;
  action?: string;
  object?: string;
};

export enum EntityActionType {
  List = 'List',
}

type EntityActionTypeMSWRestHTTPMethod = {
  [key in EntityActionType]: 'get';
};

export const entityActionTypeHTTPMethod: EntityActionTypeMSWRestHTTPMethod = {
  [EntityActionType.List]: 'get',
};

export type EntityAction<EntityModel, EntityRequest, EntityResponse> = {
  name: string;
  type: EntityActionType;
  entityType: EntityType;
  rpc: RPC<EntityRequest, EntityResponse>;
  mockPath?: string; // if we need a special path for mocks - e.g. if the path definition includes delimiters or interpolation
  mockResponse?: EntityResponse | ((req: EntityRequest) => EntityResponse);
  responseKey?: keyof EntityResponse;
  requestNameKey?: keyof EntityRequest;
  requestPayloadKey?: keyof EntityRequest;
  payloadKey?: keyof EntityModel;
  payloadIdKey?: keyof EntityModel;
};

export enum EntityType {
  KernelImage = 'KernelImage',
  KernelTemplate = 'KernelTemplate',
  KernelSpec = 'KernelSpec',
}

export enum EntityFieldType {
  Text = 'Text',
}

export type EntityField<EntityModel> = {
  name: keyof EntityModel;
  type: EntityFieldType;
  label: string;
  description?: string;
  required?: boolean;
  options?: IDropdownOption[];
  minimum?: string | number;
  relayValueTo?: [keyof EntityModel];
  readOnlyOnEdit?: boolean;
};

export type Entity<EntityModel> = {
  type: EntityType;
  name: string; // name id
  displayName: string; // to be used with tab labels
  description?: string;
  createButtonLabel?: string;
  actions: { [key in EntityActionType]?: EntityAction<EntityModel, any, any> };
  fields: EntityField<EntityModel>[]; // form fields
  columns: IColumn[]; // table columns
  order?: number;
  emptyModel: EntityModel; // empty model for create
  canBeReordered: boolean;
  filterFields?: (keyof EntityModel)[];
  sortFields?: (keyof EntityModel)[];
  requestNameKeyPrefix?: string;
  displayNameKey: keyof EntityModel;
};
