import { type IColumn } from '@fluentui/react';

import { onRenderBooleanCell, onRenderDataCell } from '../../utils/render';
import { ImagePullPolicy } from '../gen/ai/h2o/notebook/v1/base_types_pb';
import { type KernelImage, KernelImage_Type } from '../gen/ai/h2o/notebook/v1/kernel_image_pb';
import {
  KernelImageService_ListKernelImages,
  type ListKernelImagesRequest,
  type ListKernelImagesResponse,
} from '../gen/ai/h2o/notebook/v1/kernel_image_service_pb';
import { kernelImagesResponseMock } from './mocks/responses.mock';
import { type Entity, type EntityAction, EntityActionType, EntityType } from './types';
import { onRenderKernelImageType, onRenderName } from './utils';

const entityType = EntityType.KernelTemplate;

export const actions = {
  [EntityActionType.List]: {
    name: 'ListKernelImage',
    rpc: KernelImageService_ListKernelImages,
    type: EntityActionType.List,
    mockResponse: kernelImagesResponseMock,
    responseKey: 'kernelImages',
    mockPath: '/kernelImages',
    entityType,
  } as EntityAction<KernelImage, ListKernelImagesRequest, ListKernelImagesResponse>,
};

export const columns: IColumn[] = [
  {
    key: 'name',
    name: 'Name',
    fieldName: 'displayName',
    minWidth: 200,
    maxWidth: 350,
    onRender: onRenderName,
  },
  {
    key: 'type',
    name: 'Type',
    fieldName: 'type',
    minWidth: 90,
    maxWidth: 120,
    onRender: onRenderKernelImageType,
  },
  {
    key: 'disabled',
    name: 'Disabled',
    fieldName: 'disabled',
    minWidth: 80,
    maxWidth: 120,
    onRender: onRenderBooleanCell,
  },
  {
    key: 'image',
    name: 'Image',
    fieldName: 'image',
    minWidth: 350,
    onRender: onRenderDataCell,
  },
];

export const kernelImageEntity: Entity<KernelImage> = {
  type: entityType,
  name: 'KernelImage',
  createButtonLabel: '',
  displayName: 'Kernel Image',
  displayNameKey: 'displayName',
  requestNameKeyPrefix: 'kernelImage/',
  actions,
  columns,
  fields: [],
  emptyModel: {
    name: '',
    displayName: '',
    type: KernelImage_Type.UNSPECIFIED,
    image: '',
    disabled: false,
    imagePullPolicy: ImagePullPolicy.UNSPECIFIED,
    imagePullSecrets: [''],
    createTime: '',
    updateTime: '',
  },
  canBeReordered: false,
  filterFields: ['name', 'displayName'],
};
