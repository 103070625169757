import { type IColumn } from '@fluentui/react';

import { onRenderBooleanCell, onRenderDataCell } from '../../utils/render';
import { type NotebookKernelSpec } from '../gen/ai/h2o/notebook/v1/notebook_kernel_spec_pb';
import {
  type ListNotebookKernelSpecsRequest,
  type ListNotebookKernelSpecsResponse,
  NotebookKernelSpecService_ListNotebookKernelSpecs,
} from '../gen/ai/h2o/notebook/v1/notebook_kernel_spec_service_pb';
import { kernelSpecsResponseMock } from './mocks/responses.mock';
import { type Entity, type EntityAction, EntityActionType, EntityType } from './types';
import { getResourceId, onRenderName } from './utils';

const entityType = EntityType.KernelSpec;

export const actions = {
  [EntityActionType.List]: {
    name: 'ListKernelSpec',
    rpc: NotebookKernelSpecService_ListNotebookKernelSpecs,
    type: EntityActionType.List,
    mockResponse: kernelSpecsResponseMock,
    responseKey: 'notebookKernelSpecs',
    mockPath: '/notebookKernelSpecs',
    entityType,
  } as EntityAction<NotebookKernelSpec, ListNotebookKernelSpecsRequest, ListNotebookKernelSpecsResponse>,
};

export const columns: IColumn[] = [
  {
    key: 'name',
    name: 'Name',
    fieldName: 'displayName',
    minWidth: 200,
    maxWidth: 350,
    onRender: onRenderName,
  },
  {
    key: 'disabled',
    name: 'Disabled',
    fieldName: 'disabled',
    minWidth: 80,
    maxWidth: 120,
    onRender: onRenderBooleanCell,
  },
  {
    key: 'kernelImage',
    name: 'Kernel Image',
    fieldName: 'kernelImage',
    minWidth: 140,
    maxWidth: 180,
    onRender: (...arg) => onRenderDataCell(...arg, getResourceId),
  },
  {
    key: 'kernelTemplate',
    name: 'Kernel Template',
    fieldName: 'kernelTemplate',
    minWidth: 140,
    maxWidth: 180,
    onRender: (...arg) => onRenderDataCell(...arg, getResourceId),
  },
];

export const kernelSpecEntity: Entity<NotebookKernelSpec> = {
  type: entityType,
  name: 'KernelSpec',
  createButtonLabel: '',
  displayName: 'Notebook Kernel Spec',
  displayNameKey: 'displayName',
  requestNameKeyPrefix: 'kernelSpec/',
  actions,
  columns,
  fields: [],
  emptyModel: {
    name: `notebook-kernel-spec`,
    displayName: `Notebook Kernel Spec`,
    kernelImage: '',
    kernelTemplate: '',
    disabled: false,
    createTime: '',
    updateTime: '',
  },
  canBeReordered: false,
  filterFields: ['name', 'displayName'],
};
