import { ImagePullPolicy } from '../../gen/ai/h2o/notebook/v1/base_types_pb';
import { KernelImage_Type } from '../../gen/ai/h2o/notebook/v1/kernel_image_pb';
import { type ListKernelImagesResponse } from '../../gen/ai/h2o/notebook/v1/kernel_image_service_pb';
import { type ListKernelTemplatesResponse } from '../../gen/ai/h2o/notebook/v1/kernel_template_service_pb';
import { type ListNotebookKernelSpecsResponse } from '../../gen/ai/h2o/notebook/v1/notebook_kernel_spec_service_pb';
import type { BigIntString } from '../../gen/runtime';

export const kernelImagesResponseMock: ListKernelImagesResponse = {
  kernelImages: [...Array(20)].map((_item: any, index) => ({
    name: `kernel-image-name-${index + 1}`,
    displayName: `Kernel Image Display Name ${index + 1}`,
    type: KernelImage_Type.UNSPECIFIED,
    image: '',
    disabled: false,
    imagePullPolicy: ImagePullPolicy.UNSPECIFIED,
    imagePullSecrets: [''],
    createTime: new Date('2022-12-02T00:14:02Z').toUTCString(),
    updateTime: new Date('2022-12-30T01:55:37Z').toUTCString(),
  })),
};

export const kernelTemplatesResponseMock: ListKernelTemplatesResponse = {
  kernelTemplates: [...Array(20)].map((_item: any, index) => ({
    name: `workspaces/global/kernelTemplates/python-xl-${index + 1}`,
    milliCpuRequest: 1000,
    milliCpuLimit: 0,
    gpuResource: 'nvidia.com/gpu',
    gpu: 0,
    memoryBytesRequest: '64424509440' as BigIntString,
    memoryBytesLimit: '64424509440' as BigIntString,
    storageBytes: '0' as BigIntString,
    storageClassName: '',
    environmentalVariables: {},
    yamlPodTemplateSpec: '',
    disabled: false,
    maxIdleDuration: '3600s',
    createTime: new Date('2024-09-16T02:45:29.935060Z').toUTCString(),
    updateTime: '',
  })),
};

export const kernelSpecsResponseMock: ListNotebookKernelSpecsResponse = {
  notebookKernelSpecs: [...Array(20)].map((_item: any, index) => ({
    name: `kernel-spec-name-${index + 1}`,
    displayName: `Kernel Spec Display Name ${index + 1}`,
    kernelImage: `kernel-image-name-${index + 1}`,
    kernelTemplate: `workspaces/global/kernelTemplates/python-xl-${index + 1}`,
    disabled: false,
    createTime: new Date('2022-12-02T00:14:02Z').toUTCString(),
    updateTime: new Date('2022-12-30T01:55:37Z').toUTCString(),
  })),
};
