import { Stack } from '@fluentui/react';
import { type IPivotItem, PageHeader, Pivot } from '@h2oai/ui-kit';

import { kernelImageEntity } from '../../notebook/entity/KernelImage.entity';
import { kernelSpecEntity } from '../../notebook/entity/KernelSpec.entity';
import { kernelTemplateEntity } from '../../notebook/entity/KernelTemplate.entity';
import { type KernelImage } from '../../notebook/gen/ai/h2o/notebook/v1/kernel_image_pb';
import { type KernelTemplate } from '../../notebook/gen/ai/h2o/notebook/v1/kernel_template_pb';
import { type NotebookKernelSpec } from '../../notebook/gen/ai/h2o/notebook/v1/notebook_kernel_spec_pb';
import { stackStylesNoLeftNav } from '../../themes/themes';
import EntityDataContainer from './components/EntityDataContainer';

export default function NotebookAdminSettingsPage() {
  const items: IPivotItem[] = [
    {
      key: `pivot-item-content-kernel-image`,
      headerText: 'Kernel Image',
      content: <EntityDataContainer<KernelImage> entity={kernelImageEntity} />,
    },
    {
      key: `pivot-item-content-kernel-template`,
      headerText: 'Kernel Template',
      content: <EntityDataContainer<KernelTemplate> entity={kernelTemplateEntity} />,
    },
    {
      key: `pivot-item-content-kernel-spec`,
      headerText: 'Notebook Kernel Spec',
      content: <EntityDataContainer<NotebookKernelSpec> entity={kernelSpecEntity} />,
    },
  ];

  return (
    <Stack styles={stackStylesNoLeftNav}>
      <Stack horizontal horizontalAlign="space-between" style={{ marginBottom: '20px' }}>
        <Stack.Item disableShrink>
          <PageHeader pageTitle={'Notebook Admin Settings'} />
        </Stack.Item>
      </Stack>

      <Pivot items={items} />
    </Stack>
  );
}
