import { kernelImageEntity } from './KernelImage.entity';
import { kernelSpecEntity } from './KernelSpec.entity';
import { kernelTemplateEntity } from './KernelTemplate.entity';
import { type Entity, EntityType } from './types';

export const EntitiesMap = new Map<EntityType, Entity<any>>([
  [EntityType.KernelImage, kernelImageEntity],
  [EntityType.KernelTemplate, kernelTemplateEntity],
  [EntityType.KernelSpec, kernelSpecEntity],
]);
