import { type IColumn } from '@fluentui/react';

import { onRenderBooleanCell, onRenderDataCell } from '../../utils/render';
import { type KernelTemplate } from '../gen/ai/h2o/notebook/v1/kernel_template_pb';
import {
  KernelTemplateService_ListKernelTemplates,
  type ListKernelTemplatesRequest,
  type ListKernelTemplatesResponse,
} from '../gen/ai/h2o/notebook/v1/kernel_template_service_pb';
import type { BigIntString } from '../gen/runtime';
import { kernelTemplatesResponseMock } from './mocks/responses.mock';
import { type Entity, type EntityAction, EntityActionType, EntityType } from './types';
import { bytesToGiB, getResourceId } from './utils';

const entityType = EntityType.KernelImage;

export const actions = {
  [EntityActionType.List]: {
    name: 'ListKernelTemplates',
    rpc: KernelTemplateService_ListKernelTemplates,
    type: EntityActionType.List,
    mockResponse: kernelTemplatesResponseMock,
    responseKey: 'kernelTemplates',
    mockPath: '/kernelTemplates',
    entityType,
  } as EntityAction<KernelTemplate, ListKernelTemplatesRequest, ListKernelTemplatesResponse>,
};

export const columns: IColumn[] = [
  {
    key: 'name',
    name: 'Name',
    fieldName: 'name',
    minWidth: 200,
    maxWidth: 350,
    onRender: (...arg) => onRenderDataCell(...arg, getResourceId),
  },
  {
    key: 'disabled',
    name: 'Disabled',
    fieldName: 'disabled',
    minWidth: 80,
    maxWidth: 120,
    onRender: onRenderBooleanCell,
  },
  {
    key: 'gpu',
    name: 'GPU',
    fieldName: 'gpu',
    minWidth: 80,
    maxWidth: 120,
    onRender: onRenderDataCell,
  },
  {
    key: 'memoryBytesRequest',
    name: 'Memory Request',
    fieldName: 'memoryBytesRequest',
    minWidth: 110,
    maxWidth: 150,
    onRender: (...arg) => onRenderDataCell(...arg, bytesToGiB),
  },
  {
    key: 'memoryBytesLimit',
    name: 'Memory Limit',
    fieldName: 'memoryBytesLimit',
    minWidth: 100,
    maxWidth: 150,
    onRender: (...arg) => onRenderDataCell(...arg, bytesToGiB),
  },
  {
    key: 'storageBytes',
    name: 'Storage',
    fieldName: 'storageBytes',
    minWidth: 100,
    maxWidth: 150,
    onRender: (...arg) => onRenderDataCell(...arg, bytesToGiB),
  },
];

export const kernelTemplateEntity: Entity<KernelTemplate> = {
  type: entityType,
  name: 'KernelTemplate',
  createButtonLabel: '',
  displayName: 'Kernel Template',
  displayNameKey: 'name',
  requestNameKeyPrefix: 'kernelTemplate/',
  actions,
  columns,
  fields: [],
  emptyModel: {
    name: '',
    milliCpuRequest: 0,
    milliCpuLimit: 0,
    gpuResource: '',
    gpu: 0,
    memoryBytesRequest: '0' as BigIntString,
    memoryBytesLimit: '0' as BigIntString,
    storageBytes: '0' as BigIntString,
    storageClassName: '',
    environmentalVariables: {},
    yamlPodTemplateSpec: '',
    disabled: false,
    maxIdleDuration: '',
    createTime: '',
    updateTime: '',
  },
  canBeReordered: false,
  filterFields: ['name'],
};
