import { KernelImage_Type } from './gen/ai/h2o/notebook/v1/kernel_image_pb';

export const defaultDevelopmentBasePath = 'http://localhost:9002';

export const defaultBasePath =
  process.env.REACT_APP_USE_MOCK_SERVER || process.env.NODE_ENV === 'test' ? defaultDevelopmentBasePath : '';

export const defaultWorkspaceID = 'global';

export const defaultWorkspaceName = `workspaces/${defaultWorkspaceID}`;

export const kernelImageTypeMap: { [key in KernelImage_Type]: string } = {
  [KernelImage_Type.UNSPECIFIED]: 'Unspecified',
  [KernelImage_Type.PYTHON]: 'Python',
  [KernelImage_Type.R]: 'R',
  [KernelImage_Type.SPARK_PYTHON]: 'Spark Python',
  [KernelImage_Type.SPARK_R]: 'Spark R',
};
